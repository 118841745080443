import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'stack-delta-category-segment',
  templateUrl: './category-segment.component.html',
  styleUrls: ['./category-segment.component.css']
})
export class CategorySegmentComponent implements OnInit {

  @Input() items = [];
  @Input() keyId = '_id';
  @Input() keyName = 'category_name';
  @Input() color;
  @Input() scrollable: boolean = true;
  @Input() position: any = 'center';
  @Input() selectedValue = null;

  @Input() allLabel = 'All';

  @Output() itemChange: EventEmitter<any> = new EventEmitter();
  @Output() itemSelect: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  segmentChanged(ev: any) {
    this.itemChange.emit(ev);
    if (this.selectedValue) {
      const _index = this.items.findIndex((item: any) => item[this.keyName] == this.selectedValue);
      const _item = this.items.find((item: any) => item[this.keyName] == this.selectedValue);
      if (_index != -1) {
        this.onIonSelect(ev, _index + 1, _item[this.keyId]);
      }
    }
  }

  onIonSelect(ev, index, id) {
    this.itemSelect.emit({ el: ev, index: index });
    let segmentActive = document.getElementById(id);
    const vm = this;
    setTimeout(() => {
      if (segmentActive) {
        segmentActive.scrollIntoView({behavior: "smooth", inline: vm.position });
      }
    }, 500);
  }
}
