import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'stack-delta-top-categories-slider',
  templateUrl: './top-categories-slider.component.html',
  styleUrls: ['./top-categories-slider.component.css']
})
export class TopCategoriesSliderComponent implements OnInit {

  slideOpts = {
    initialSlide: 1,
    speed: 400
  };
  
  constructor() { }

  ngOnInit() {
  }

}
