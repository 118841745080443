import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel.component';
import { IonicModule } from '@ionic/angular';
import  { NgxProgressiveImgLoaderModule } from 'ngx-progressive-img-loader';

@NgModule({
  declarations: [CarouselComponent],
  imports: [
    CommonModule,
    IonicModule,
    NgxProgressiveImgLoaderModule
  ],
  exports: [CarouselComponent]
})
export class CarouselModule { }
